<template>
  <span>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <b-select v-model="selectedChoice" :disabled="choices.length <= 1" @change="onChange">
      <b-select-option v-for="choice of choices" :key="choice.id" :value="choice">
        {{ choice.name }}
      </b-select-option>
    </b-select>
  </span>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
import { mapMutations, mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import LoadingGif from '@/components/Controls/LoadingGif'
import { makeChoice } from '@/types/base'

export default {
  name: 'location-type-select',
  mixins: [BackendMixin],
  components: {
    LoadingGif,
  },
  prop: ['value'],
  props: {
    value: Object,
  },
  data() {
    return {
      loadingName: 'location-type-select',
      selectedChoice: null,
      choices: [],
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async init() {
      await this.loadChoices()
    },
    onChange() {
      this.$emit('input', this.selectedChoice)
    },
    async loadChoices() {
      const backendApi = new BackendApi('get', '/stats/api/location-type-choices/')
      try {
        let resp = await backendApi.callApi()
        this.choices = resp.data.map(elt => makeChoice(elt))
        if (this.choices.length > 0) {
          this.selectedChoice = this.choices[0]
          this.onChange()
        }
      } catch (err) {
        this.addError(this.getErrorText(err))
      }
    },
  },
}
</script>

<style lang="less">
</style>
